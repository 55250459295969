
import { defineComponent, PropType } from 'vue';
import { StarTwoTone, LikeOutlined, MessageFilled } from '@ant-design/icons-vue';

export type IconType = 'star' | 'like' | 'message';

export default defineComponent({
  name: 'IconText',
  props: {
    type: {
      type: String as PropType<IconType>,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    StarTwoTone,
    LikeOutlined,
    MessageFilled,
  },
});
